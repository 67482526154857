import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { CDataButton, ButtonType } from "src/components/buttons/CDataButton";
import { CDataTooltip, CDataTooltipType } from "src/components/CDataTooltip";
import { CDataTextField } from "src/components/form/CDataTextField";
import { CDataTypography } from "src/components/text/CDataTypography";
import { preventNonIntegerInput } from "src/utility/InputSanitization";
import { UpdatePlanContext } from "../UpdatePlanContext";

interface ISummaryAdjustmentRow {
  item: string;
  priceLabel: string;
  quantity: number;
  setQuantity: (quantity: number) => void;
  minimumQuantity: number;
  className: string;
}

export const SummaryAdjustmentRow = (props: ISummaryAdjustmentRow) => {
  const {
    item,
    priceLabel,
    quantity,
    setQuantity,
    minimumQuantity,
    className,
  } = props;

  const { setContinueEnabled } = useContext(UpdatePlanContext);

  const [invalidMessage, setInvalidMessage] = useState("");

  const maximumQuantity = 99;
  const quantityAtMinimum = quantity <= minimumQuantity;
  const quantityIsZero = quantity === 0;

  // This useEffect keeps the errors in sync whether the user types a number or uses the buttons
  useEffect(() => {
    if (quantity > maximumQuantity) {
      setInvalidMessage(`Value cannot exceed ${maximumQuantity}.`);
      setContinueEnabled(false);
    } else if (quantity < minimumQuantity) {
      setInvalidMessage(`Value must be at least ${minimumQuantity}.`);
      setContinueEnabled(false);
    } else {
      setInvalidMessage("");
      setContinueEnabled(true);
    }
  }, [quantity, minimumQuantity, setContinueEnabled]);

  return (
    <div className={`${className} summary-info-row`}>
      <div>
        <CDataTypography
          variant="typography-variant-body-medium"
          color="typography-color-text-dark"
        >
          {item}:
        </CDataTypography>
        <CDataTypography
          variant="typography-variant-tooltip-medium"
          color="typography-color-sidebar-gray"
        >
          {priceLabel}
        </CDataTypography>
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-end gap-2">
          <CDataTooltip
            type={CDataTooltipType.Dark}
            title={
              quantityAtMinimum && !quantityIsZero // No tooltip needed if the add-on is at 0
                ? "Reduce totals by removing them from within the designated areas of your account."
                : ""
            }
          >
            <div>
              <CDataButton
                buttonType={ButtonType.OutlineTransparent}
                disabled={quantityAtMinimum || quantityIsZero}
                onClick={() =>
                  !quantityAtMinimum && !quantityIsZero
                    ? setQuantity(quantity - 1)
                    : null
                }
              >
                <i
                  className={classNames("fa-minus fa-regular fa-xs", {
                    "opacity-disabled": quantityAtMinimum || quantityIsZero,
                  })}
                />
              </CDataButton>
            </div>
          </CDataTooltip>
          <CDataTextField
            className="adjustment-number-box"
            value={quantity}
            type={"text"}
            inputBaseClassName="px-1"
            inputProps={{ style: { textAlign: "center" } }}
            onChange={(event) => {
              const inputAsNumber = preventNonIntegerInput(event.target.value);
              setQuantity(inputAsNumber);
            }}
            isInvalid={!!invalidMessage}
          />
          <CDataButton
            buttonType={ButtonType.OutlineTransparent}
            disabled={quantity >= maximumQuantity}
            onClick={() => {
              if (quantity < maximumQuantity) {
                setQuantity(quantity + 1);
              }
            }}
          >
            <i
              className={classNames("fa-plus fa-regular fa-xs", {
                "opacity-disabled": quantity >= maximumQuantity,
              })}
            />
          </CDataButton>
        </div>
        {!!invalidMessage && (
          <CDataTypography
            variant="typography-variant-helper-text"
            color="typography-color-danger"
          >
            {invalidMessage}
          </CDataTypography>
        )}
      </div>
    </div>
  );
};
