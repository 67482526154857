import { CDToggleButton } from "src/components/buttons/CDToggleButton";
import { CDToggleButtonGroup } from "src/components/buttons/CDToggleButtonGroup";
import { UpdatePlanContext } from "./UpdatePlanContext";
import { useContext } from "react";

export const PlanBillingFrequencySelector = () => {
  const { isYearly, setIsYearly } = useContext(UpdatePlanContext);

  return (
    <CDToggleButtonGroup exclusive className="monthly-yearly-toggle mb-4">
      <CDToggleButton
        value="monthly"
        selected={!isYearly}
        onChange={() => setIsYearly(false)}
      >
        Monthly
      </CDToggleButton>
      <CDToggleButton
        value="yearly"
        selected={isYearly}
        onChange={() => setIsYearly(true)}
      >
        Yearly (save 20%)
      </CDToggleButton>
    </CDToggleButtonGroup>
  );
};
