import "./PlanSources.scss";
import { useAppSelector } from "src/redux/hooks";
import { compareDrivers } from "src/components/drivers/DriverUtilities";
import { GridTable, GridTile } from "src/components/tables/GridTable/GridTable";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataTypography } from "src/components/text/CDataTypography";
import { useGridFilter } from "src/components/tables/GridTable/useGridFilter";
import { SimpleTile } from "src/components/tables/GridTable/SimpleTile";
import { DriverIcon } from "src/components/drivers/DriverIcon";
import {
  PopularProduct,
  PopularProducts,
} from "src/pages/initialSetup/Survey/Steps/2-SelectDataSources/PopularProducts";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { getBaseIconUrl } from "src/components/drivers/DriverIconFactory";
import { useMemo, useContext } from "react";
import { UpdatePlanContext } from "../UpdatePlanContext";
import { DriverCategoryBadge } from "src/components/drivers/DriverCategoryBadge";
import { DriverCategoryNames } from "src/utility/enums/DriverCategoryNames";

export function PlanSources() {
  const driversList = useAppSelector((state) => state.driversList);
  const { usage } = useContext(UpdatePlanContext);
  const sourcesUsed = usage.dataSources;

  // These functions are memoized to prevent costly re-renders that delay page updates needlessly
  const drivers = useMemo(() => {
    const mappedDrivers = driversList.drivers!.map((driver) => driver);
    const visibleDrivers = mappedDrivers.filter((driver) => !driver.hidden);

    return visibleDrivers.sort(compareDrivers);
  }, [driversList.drivers]);

  const dataSourceCards = useMemo(() => {
    if (drivers.length > 0) {
      const newDataSourceCards: GridTile[] = drivers.map((driver) => {
        const categoryBadge = (
          <DriverCategoryBadge driverCategory={driver.driverCategory!} />
        );

        return {
          name: driver.niceName ?? "",
          category: driver.category ?? "",
          tile: (
            <SimpleTile
              name={driver.niceName ?? ""}
              onClick={() => null}
              isSelected={false}
              icon={<DriverIcon driver={driver.driver!} />}
              isReadOnly={true}
              endAdornment={categoryBadge}
            />
          ),
        };
      });

      return newDataSourceCards;
    }

    return [];
  }, [drivers]);

  const gridFilter = useGridFilter<GridTile>({
    tiles: dataSourceCards,
    categories: undefined,
  });

  const getCurrentDriverIcons = useMemo((): PopularProduct[] => {
    if (!sourcesUsed || sourcesUsed.length === 0) return [];

    const extendedDriversInUse = driversList.drivers!.filter((driver) =>
      sourcesUsed.includes(driver.driver!),
    );

    return extendedDriversInUse.map((driver) => {
      return {
        key: driver.driver!,
        title:
          DriverCategoryNames[
            driver.driverCategory as keyof typeof DriverCategoryNames
          ],
        isSelected: false,
        img: (
          <img
            src={`${getBaseIconUrl()}/${driver.driver}.svg`}
            className="card-img-top"
            alt={`${driver.driver} icon`}
          />
        ),
      };
    });
  }, [sourcesUsed, driversList.drivers]);

  const CurrentSourcesCircles = useMemo(
    () => (
      <div className="mt-3 mb-4">
        <PopularProducts
          productsTitle="Current Sources:"
          products={getCurrentDriverIcons}
          onClickProduct={() => null}
          noColoring={true}
          isUpdatePlanPage={true}
        />
      </div>
    ),
    [getCurrentDriverIcons],
  );

  return (
    <CDataCard roundedCorners className="plan-sources-card">
      <CDataCardBody>
        <CDataTypography
          variant="typography-variant-headline-4"
          className="mb-3"
        >
          Data Sources
        </CDataTypography>
        <CDataTypography
          variant="typography-variant-body-regular"
          className="mb-3"
        >
          {`Not sure which data sources you'll need? Explore the list below to
          choose the best options for your plan.`}
        </CDataTypography>
        <GridTable
          tiles={dataSourceCards}
          emptyElement={<div className="text-center">No results found.</div>}
          className={"restricted-sources-height"}
          gridFilter={gridFilter}
          elementUnderSearch={CurrentSourcesCircles}
        />
      </CDataCardBody>
    </CDataCard>
  );
}
