import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { PlanDividerLine } from "../PlanDividerLine";
import { useContext } from "react";
import { UpdatePlanContext } from "../UpdatePlanContext";
import { PlanDividerDot } from "../PlanDividerDot";
import { BillingPlanIds } from "src/utility/enums/BillingPlanIds";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { InfoIcon } from "src/components/InfoIcon";
import { SummaryInfoRow } from "./SummaryInfoRow";
import { SummaryAdjustmentRow } from "./SummaryAdjustmentRow";
import {
  growthIncludedSourcesCount,
  growthIncludedUsers,
  standardIncludedSourcesCount,
  standardIncludedUsers,
} from "../planLimits";

export const PlanSummary = () => {
  const {
    isYearly,
    selectedPlan,
    standardPlanData,
    growthPlanData,
    userLimit,
    setUserLimit,
    sourceLimit,
    setSourceLimit,
    continueEnabled,
    usage,
  } = useContext(UpdatePlanContext);

  const usersUsed = usage.usersCount;
  const sourcesUsedCount = usage.dataSourcesCount;
  const isStandardPlanSelected = selectedPlan === BillingPlanIds.Standard2025;
  const includedUsers = isStandardPlanSelected
    ? standardIncludedUsers
    : growthIncludedUsers;
  const includedSourcesCount = isStandardPlanSelected
    ? standardIncludedSourcesCount
    : growthIncludedSourcesCount;

  const planName = isStandardPlanSelected ? "Standard" : "Growth";

  const currentPlanData = isStandardPlanSelected
    ? standardPlanData
    : growthPlanData;

  function calculateTotalPrice() {
    let totalPrice = 0;
    if (isYearly) {
      totalPrice += currentPlanData.planPriceBilledYearly * 12;
      totalPrice += currentPlanData.userPriceBilledYearly * 12 * userLimit;
      totalPrice += currentPlanData.sourcePriceBilledYearly * 12 * sourceLimit;
    } else {
      totalPrice += currentPlanData.planPriceBilledMonthly;
      totalPrice += currentPlanData.userPriceBilledMonthly * userLimit;
      totalPrice += currentPlanData.sourcePriceBilledMonthly * sourceLimit;
    }

    return totalPrice.toLocaleString();
  }

  function getPlanPriceLabel() {
    if (isYearly) {
      return `$${currentPlanData.planPriceBilledYearly * 12} per year`;
    } else {
      return `$${currentPlanData.planPriceBilledMonthly} per month`;
    }
  }

  function getUserPriceLabel() {
    if (isYearly) {
      return `+$${currentPlanData.userPriceBilledYearly * 12} per user per year`;
    } else {
      return `+$${currentPlanData.userPriceBilledMonthly} per user per month`;
    }
  }

  function getSourcePriceLabel() {
    if (isYearly) {
      return `+$${currentPlanData.sourcePriceBilledYearly * 12} per source per year`;
    } else {
      return `+$${currentPlanData.sourcePriceBilledMonthly} per source per month`;
    }
  }

  function getMinimumUsers() {
    return Math.max(0, usersUsed - includedUsers);
  }

  function getMinimumSources() {
    return Math.max(0, sourcesUsedCount - includedSourcesCount);
  }

  return (
    <CDataCard roundedCorners className="plan-summary">
      <CDataCardBody>
        <CDataTypography
          variant="typography-variant-headline-4"
          color="typography-color-dark-grey"
          className="mb-3"
        >
          Plan Summary
        </CDataTypography>
        <CDataTypography
          variant="typography-variant-card-title"
          color="typography-color-dark-grey"
          className="mb-2"
        >
          Subscription
        </CDataTypography>
        <PlanDividerLine className="mb-3" />
        <SummaryInfoRow
          item="Billing Frequency"
          value={isYearly ? "Yearly" : "Monthly"}
        />
        <SummaryInfoRow item="Plan" value={planName} />
        <SummaryInfoRow item="Pricing" value={getPlanPriceLabel()} />
        <SummaryInfoRow
          className="mb-3"
          item="Includes"
          value={
            <>
              {includedSourcesCount} Data Source
              <PlanDividerDot />
              {includedUsers} User
            </>
          }
        />
        <div className="d-flex gap-1 mb-2 align-items-center">
          <CDataTypography
            variant="typography-variant-card-title"
            color="typography-color-dark-grey"
          >
            Add-ons:
          </CDataTypography>
          <InfoIcon
            tooltipMessage="These customizations reflect your current account usage. You can reduce totals 
            by removing them from within the designated areas of your account."
          />
        </div>
        <PlanDividerLine className="mb-3" />
        <SummaryAdjustmentRow
          item="Users"
          priceLabel={getUserPriceLabel()}
          quantity={userLimit}
          setQuantity={setUserLimit}
          minimumQuantity={getMinimumUsers()}
          className={"mb-3"}
        />
        <SummaryAdjustmentRow
          item="Data Sources"
          priceLabel={getSourcePriceLabel()}
          quantity={sourceLimit}
          setQuantity={setSourceLimit}
          minimumQuantity={getMinimumSources()}
          className={"mb-4"}
        />
        <CDataTypography
          variant="typography-variant-headline-3"
          color="typography-color-dark-grey"
          className="mb-2"
        >
          Total
        </CDataTypography>
        <PlanDividerLine className="mb-4" />
        <div className="d-flex flex-column mb-4">
          <div className="d-flex flex-row align-items-baseline gap-1 justify-content-center">
            <CDataTypography
              variant="typography-variant-jumbo"
              color="typography-color-dark-grey"
            >
              ${continueEnabled ? calculateTotalPrice() : "--"}
            </CDataTypography>
            <CDataTypography
              variant="typography-variant-card-title-bold"
              color="typography-color-dark-grey"
            >
              per {isYearly ? "year" : "month"}
            </CDataTypography>
          </div>
          <CDataTypography
            variant="typography-variant-caption"
            color="typography-color-sidebar-gray"
            className="text-center"
          >
            Plus applicable taxes.
          </CDataTypography>
        </div>
        <CDataButton
          buttonType={ButtonType.Primary}
          className="w-100"
          disabled={!continueEnabled}
        >
          Continue <i className="fa-arrow-right fa-regular ms-1" />
        </CDataButton>
      </CDataCardBody>
    </CDataCard>
  );
};
