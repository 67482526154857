import { useContext } from "react";
import { BillingPlanCard } from "./BillingPlanCard";
import { BusinessBillingPlanCard } from "./BusinessBillingPlanCard";
import { UpdatePlanContext } from "../UpdatePlanContext";
import {
  growthIncludedSourcesCount,
  growthIncludedUsers,
  standardIncludedSourcesCount,
  standardIncludedUsers,
} from "../planLimits";

export const PlanCards = () => {
  const { standardPlanData, growthPlanData } = useContext(UpdatePlanContext);

  return (
    <div className="plan-card-section">
      <BillingPlanCard
        stripeBillingPlan={standardPlanData}
        includedSources={standardIncludedSourcesCount}
        includedUsers={standardIncludedUsers}
        dataSourceTypes={"Standard Only"}
        features={["Virtual Datasets"]}
      />
      <BillingPlanCard
        stripeBillingPlan={growthPlanData}
        includedSources={growthIncludedSourcesCount}
        includedUsers={growthIncludedUsers}
        dataSourceTypes={"Standard & Growth"}
        features={[
          "Virtual Datasets",
          "Query Federation",
          "Derived Views",
          "Jobs (Caching & Scheduled Queries)",
          "AI SQL Generator",
        ]}
      />
      <BusinessBillingPlanCard
        id={29}
        features={[
          "Custom Data Sources",
          "Custom Users",
          "Virtual Datasets",
          "Query Federation",
          "Derived Views",
          "Jobs (Caching & Scheduled Queries)",
          "AI SQL Generator",
          "SSO",
        ]}
        dataSourceTypes={"All Tiers"}
      />
    </div>
  );
};
