import { CDataTypography } from "src/components/text/CDataTypography";
import { PlanDividerDot } from "../PlanDividerDot";

interface IBillingPlanFeatureList {
  features: string[];
  dataSourceTypes: string;
}

export const BillingPlanFeatureList = (props: IBillingPlanFeatureList) => {
  const featureList = props.features.map((feature, index) => (
    <div className="d-flex align-items-center mb-2" key={index}>
      <i className="fa-sharp fa-solid fa-check feature-checkmark me-2"></i>
      <CDataTypography color="typography-color-dark-grey">
        {feature}
      </CDataTypography>
      {index === 0 && (
        <>
          <PlanDividerDot />
          <CDataTypography
            variant="typography-variant-tooltip-medium"
            color="typography-color-text-muted"
          >
            {props.dataSourceTypes}
          </CDataTypography>
        </>
      )}
    </div>
  ));

  return (
    <div className="feature-list-section">
      <CDataTypography
        variant="typography-variant-body-bold"
        color="typography-color-dark-grey"
        className="mb-2"
      >
        Includes:
      </CDataTypography>
      {featureList}
    </div>
  );
};
