import "./BillingPlanCard.scss";

import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { PlanDividerDot } from "../PlanDividerDot";
import { BillingPlanFeatureList } from "./BillingPlanFeatureList";
import { PlanDividerLine } from "../PlanDividerLine";
import classNames from "classnames";
import { useContext } from "react";
import { UpdatePlanContext } from "../UpdatePlanContext";
import { ISelfServiceBillingPlan } from "src/bffmodels/Billing/SelfServiceBilling/ISelfServiceBillingPlan";

interface IBillingPlanCard {
  stripeBillingPlan: ISelfServiceBillingPlan;
  includedSources: number;
  includedUsers: number;
  dataSourceTypes: string;
  features: string[];
}

export const BillingPlanCard = (props: IBillingPlanCard) => {
  const {
    stripeBillingPlan,
    includedSources,
    includedUsers,
    dataSourceTypes,
    features,
  } = props;

  const { isYearly, selectedPlan, setSelectedPlan } =
    useContext(UpdatePlanContext);

  const {
    billingItemId,
    name,
    planPriceBilledMonthly,
    planPriceBilledYearly,
    userPriceBilledMonthly,
    userPriceBilledYearly,
    sourcePriceBilledMonthly,
    sourcePriceBilledYearly,
  } = stripeBillingPlan;

  const isPlanActive = selectedPlan === billingItemId;
  const planPrice = isYearly ? planPriceBilledYearly : planPriceBilledMonthly;
  const userPrice = isYearly ? userPriceBilledYearly : userPriceBilledMonthly;
  const sourcePrice = isYearly
    ? sourcePriceBilledYearly
    : sourcePriceBilledMonthly;

  const getBottomCardButton = () => {
    if (isPlanActive) {
      return (
        <CDataButton buttonType={ButtonType.Primary} disabled>
          <i className="fa-regular fa-sm fa-check me-1" />
          Selected Plan
        </CDataButton>
      );
    } else {
      return (
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={() => setSelectedPlan(billingItemId)}
        >
          Select Plan
        </CDataButton>
      );
    }
  };

  const featuresWithSourcesAndUsers: string[] = [
    `${includedSources} Data Source${includedSources > 1 ? "s" : ""}`,
    `${includedUsers} User${includedUsers > 1 ? "s" : ""}`,
    ...features,
  ];

  return (
    <CDataCard
      className={classNames("billing-plan-card mb-0", { active: isPlanActive })}
      roundedCorners
    >
      <CDataCardBody className="billing-plan-card-body">
        <div className="price-section">
          <CDataTypography
            variant="typography-variant-headline-4"
            color="typography-color-dark-grey"
            className="mb-3"
          >
            {name}
          </CDataTypography>
          <div className="d-flex align-items-baseline">
            <CDataTypography
              variant="typography-variant-headline-1"
              color="typography-color-dark-grey"
              className="me-2"
            >
              ${planPrice}
            </CDataTypography>
            <CDataTypography
              variant="typography-variant-body-regular"
              color="typography-color-text-regular"
            >
              per month billed {isYearly ? "yearly" : "monthly"}
            </CDataTypography>
          </div>
          <CDataTypography
            variant="typography-variant-caption"
            color="typography-color-success"
          >
            Save 20% when billed annually
          </CDataTypography>
        </div>
        <PlanDividerLine className="my-4" />
        <BillingPlanFeatureList
          features={featuresWithSourcesAndUsers}
          dataSourceTypes={dataSourceTypes}
        />
        <PlanDividerLine className="mb-2" />
        <div className="addon-section mb-3">
          <CDataTypography
            variant="typography-variant-tooltip-medium"
            color="typography-color-text-muted"
            className="me-2"
          >
            Add-Ons:
          </CDataTypography>
          <CDataTypography
            variant="typography-variant-tooltip"
            color="typography-color-text-muted"
          >
            +${userPrice} per user
            <PlanDividerDot />
            +$
            {sourcePrice} per source
          </CDataTypography>
        </div>
        {getBottomCardButton()}
      </CDataCardBody>
    </CDataCard>
  );
};
