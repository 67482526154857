import { createContext } from "react";
import { ISelfServiceBillingPlan } from "src/bffmodels/Billing/SelfServiceBilling/ISelfServiceBillingPlan";
import { IBillingUsage } from "src/models";

export interface IUpdatePlanContext {
  isYearly: boolean;
  setIsYearly: (isYearly: boolean) => void;
  selectedPlan: number;
  setSelectedPlan: (plan: number) => void;
  standardPlanData: ISelfServiceBillingPlan;
  growthPlanData: ISelfServiceBillingPlan;
  userLimit: number;
  setUserLimit: (limit: number) => void;
  sourceLimit: number;
  setSourceLimit: (limit: number) => void;
  continueEnabled: boolean;
  setContinueEnabled: (enabled: boolean) => void;
  usage: IBillingUsage;
}

export const UpdatePlanContext = createContext<IUpdatePlanContext>(null!);
