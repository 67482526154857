import { CDataCard } from "src/components/card/CDataCard";
import { CDataCardBody } from "src/components/card/CDataCardBody";
import { CDataTypography } from "src/components/text/CDataTypography";
import { PlanDividerLine } from "../PlanDividerLine";
import { BillingPlanFeatureList } from "./BillingPlanFeatureList";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { getSalesEmailAddress } from "src/utility/LocalizedEmailAddresses";
import classNames from "classnames";
import { useContext } from "react";
import { UpdatePlanContext } from "../UpdatePlanContext";

interface IBusinessBillingPlanCard {
  id: number;
  features: string[];
  dataSourceTypes: string;
}

export const BusinessBillingPlanCard = (props: IBusinessBillingPlanCard) => {
  const { id, features, dataSourceTypes } = props;
  const { selectedPlan } = useContext(UpdatePlanContext);
  const isPlanActive = selectedPlan === id;

  const handleContactSales = () => {
    window.location.href = `mailto:${getSalesEmailAddress()}`;
  };

  return (
    <CDataCard
      className={classNames("billing-plan-card mb-0", { active: isPlanActive })}
      roundedCorners
    >
      <CDataCardBody className="billing-plan-card-body">
        <div className="price-section">
          <CDataTypography
            variant="typography-variant-headline-4"
            color="typography-color-dark-grey"
            className="mb-3"
          >
            Business Plan
          </CDataTypography>
          <div className="d-flex align-items-baseline">
            <CDataTypography
              variant="typography-variant-headline-1"
              color="typography-color-dark-grey"
              className="me-2"
            >
              Custom Pricing
            </CDataTypography>
          </div>
          <CDataTypography
            variant="typography-variant-caption"
            color="typography-color-dark-blue"
          >
            Access savings & features not available in Standard or Growth
          </CDataTypography>
        </div>
        <PlanDividerLine className="my-4" />
        <BillingPlanFeatureList
          features={features}
          dataSourceTypes={dataSourceTypes}
        />
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={handleContactSales}
        >
          Contact Sales
        </CDataButton>
      </CDataCardBody>
    </CDataCard>
  );
};
