import { ReactNode } from "react";
import { CDataTypography } from "src/components/text/CDataTypography";

interface ISummaryInfoRow {
  item: string;
  value: ReactNode;
  className?: string;
}

export const SummaryInfoRow = (props: ISummaryInfoRow) => {
  const { item, value, className } = props;
  return (
    <div className={`${className ?? "mb-2"} summary-info-row`}>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-dark-grey"
      >
        {item}:
      </CDataTypography>
      <CDataTypography color="typography-color-dark-grey">
        {value}
      </CDataTypography>
    </div>
  );
};
