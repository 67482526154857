import { Route, Routes } from "react-router-dom";
import Page404 from "../auth/Page404";
import Settings from "./Settings";
import { UpdatePlan } from "./BillingInfoTab/UpdatePlan/UpdatePlan";

const SettingsRoutes = () => {
  return (
    <Routes>
      {/* Settings route */}
      <Route path="/" element={<Settings />} />

      <Route path="updatePlan" element={<UpdatePlan />} />

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default SettingsRoutes;
