import { Divider } from "@mui/material";

interface IPlanDividerLine {
  className?: string;
}

export const PlanDividerLine = (props: IPlanDividerLine) => {
  return (
    <div className={props.className ?? ""}>
      <Divider aria-hidden="true" sx={{ borderBottom: "2px solid #DAE0E6" }} />
    </div>
  );
};
