import { styled, ToggleButton, ToggleButtonProps } from "@mui/material";
import { CDataTypography } from "../text/CDataTypography";

interface ICDToggleButton extends ToggleButtonProps {}

/** Custom stylings to override the default MUI button behavior */
const StyledToggleButton = styled(ToggleButton)({
  backgroundColor: "#ffffff",
  padding: "4px 11.2px",
  letterSpacing: "normal",
  textTransform: "none",

  "&.Mui-selected": {
    backgroundColor: "#3f4b57",

    "&:hover": {
      backgroundColor: "#2a3540",
    },
  },

  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
});

export const CDToggleButton = (props: ICDToggleButton) => {
  return (
    <StyledToggleButton {...props}>
      <CDataTypography
        variant="typography-variant-body-medium"
        color={
          props.selected
            ? "typography-color-white"
            : "typography-color-text-regular"
        }
      >
        {props.children}
      </CDataTypography>
    </StyledToggleButton>
  );
};
