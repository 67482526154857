import { CDataTypography } from "src/components/text/CDataTypography";
import { getSalesEmailAddress } from "src/utility/LocalizedEmailAddresses";

export const PlanContactInformation = () => {
  const salesEmail = getSalesEmailAddress();
  const emailLink = <a href={`mailto:${salesEmail}`}>{salesEmail}</a>;

  return (
    <div className="contact-information">
      <CDataTypography className="mb-2">
        To learn more about our Business Plan, please contact {emailLink}.
      </CDataTypography>
      <div className="d-flex flex-direction-row align-items-center">
        <i className="fa-solid fa-phone me-1 phone-icon" />
        <CDataTypography
          variant="typography-variant-headline-5"
          color="typography-color-dark-grey"
          className="me-1"
        >
          Available Monday - Friday, 9am- 6pm EST:
        </CDataTypography>
        <CDataTypography
          variant="typography-variant-body-regular"
          color="typography-color-dark-grey"
        >
          (800) 235-7250 (within US) +01 (919) 928-5214 (outside US)
        </CDataTypography>
      </div>
    </div>
  );
};
