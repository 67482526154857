import {
  styled,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  ToggleButtonGroupProps,
} from "@mui/material";

interface ICDToggleButtonGroup extends ToggleButtonGroupProps {}

/** Custom stylings to override the default MUI grouping behavior */
const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  [`& .${toggleButtonGroupClasses.firstButton}`]: {
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  [`& .${toggleButtonGroupClasses.lastButton}`]: {
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
});

export const CDToggleButtonGroup = (props: ICDToggleButtonGroup) => {
  return (
    <StyledToggleButtonGroup {...props}>
      {props.children}
    </StyledToggleButtonGroup>
  );
};
