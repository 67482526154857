import "./UpdatePlan.scss";
import { useEffect, useState } from "react";
import TitleWithBackButton from "src/components/TitleWithBackButton";
import { Container } from "@mui/material";
import { PlanCards } from "./PlanCards/PlanCards";
import { PlanContactInformation } from "./PlanContactInformation";
import { PlanBillingFrequencySelector } from "./PlanBillingFrequencySelector";
import { UpdatePlanContext } from "./UpdatePlanContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSelfServicePlans } from "./getSelfServicePlans";
import Loader from "src/components/Loader";
import { PlanSummary } from "./Summary/PlanSummary";
import { PlanSources } from "./Sources/PlanSources";
import { BillingPlanIds } from "src/utility/enums/BillingPlanIds";
import { useAppSelector } from "src/redux/hooks";
import { useNavigate } from "react-router-dom";
import { BillingStatus, IBillingUsage } from "src/models";
import {
  growthIncludedSourcesCount,
  growthIncludedUsers,
  standardIncludedSourcesCount,
  standardIncludedUsers,
} from "./planLimits";
import { getBillingUsage } from "src/pages/users/api/getBillingUsage";

export const UpdatePlan = () => {
  const subscription = useAppSelector((state) => state.subscription);
  const existingPlanId = subscription.plan?.id;
  const isTrial =
    subscription.billingStatus === BillingStatus.Trial ||
    subscription.billingStatus === BillingStatus.TrialEnding;
  const navigate = useNavigate();

  const [isYearly, setIsYearly] = useState(subscription.isYearly ?? false);
  const [selectedPlan, setSelectedPlan] = useState<number>(existingPlanId ?? 0);
  const [userLimit, setUserLimit] = useState(0);
  const [sourceLimit, setSourceLimit] = useState(0);
  const [continueEnabled, setContinueEnabled] = useState(true);
  const [usage, setUsage] = useState<IBillingUsage>();

  const { data: selfServicePlanList, isLoading: loadingPlans } = useQuery({
    queryKey: ["selfServiceBilling/plans"],
    queryFn: getSelfServicePlans,
    meta: {
      errorMessage:
        "Failed to retrieve billing plans due to the following error:",
    },
  });

  const { mutateAsync: loadUsage, isPending: loadingUsage } = useMutation({
    mutationKey: ["/billing/usage"],
    mutationFn: getBillingUsage,
    meta: {
      errorMessage:
        "Failed to retrieve current billing usage due to the following error:",
    },
  });

  useEffect(() => {
    async function loadUsageOnLoad() {
      const loadedUsage = await loadUsage();
      setUsage(loadedUsage);

      setUserLimit(
        Math.max(
          0,
          loadedUsage.usersCount -
            (existingPlanId === BillingPlanIds.Standard2025
              ? standardIncludedUsers
              : growthIncludedUsers),
        ),
      );

      setSourceLimit(
        Math.max(
          0,
          loadedUsage.dataSourcesCount -
            (isTrial
              ? standardIncludedSourcesCount
              : growthIncludedSourcesCount),
        ),
      );
    }

    loadUsageOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only standard, growth, and trial 2025 plans should be accessing this page. All others return to settings.
  const permittedPlanIds = [
    BillingPlanIds.Standard2025,
    BillingPlanIds.Growth2025,
    ...(isTrial ? [BillingPlanIds.Business2025] : []),
  ];

  if (!existingPlanId || !permittedPlanIds.includes(existingPlanId)) {
    navigate("/settings");
  }

  const standardPlanData = selfServicePlanList?.plans.find(
    (plan) => plan.billingItemId === BillingPlanIds.Standard2025,
  );
  const growthPlanData = selfServicePlanList?.plans.find(
    (plan) => plan.billingItemId === BillingPlanIds.Growth2025,
  );

  if (loadingPlans || loadingUsage) return <Loader />;

  if (!standardPlanData || !growthPlanData) {
    // We cannot render this page if the plan data fails to load.
    throw new Error(
      "Failed to retrieve information about billing plan options.",
    );
  }

  if (!usage) {
    throw new Error("Failed to retrieve information about billing usage.");
  }

  return (
    <UpdatePlanContext.Provider
      value={{
        isYearly: isYearly,
        setIsYearly: setIsYearly,
        selectedPlan: selectedPlan,
        setSelectedPlan: setSelectedPlan,
        standardPlanData: standardPlanData,
        growthPlanData: growthPlanData,
        userLimit: userLimit,
        setUserLimit: setUserLimit,
        sourceLimit: sourceLimit,
        setSourceLimit: setSourceLimit,
        continueEnabled: continueEnabled,
        setContinueEnabled: setContinueEnabled,
        usage: usage,
      }}
    >
      <Container className="update-billing-plan">
        <TitleWithBackButton title={"Update Plan"} />
        <PlanBillingFrequencySelector />
        <PlanCards />
        <PlanContactInformation />
        <div className="source-and-summary-section">
          <PlanSources />
          <PlanSummary />
        </div>
      </Container>
    </UpdatePlanContext.Provider>
  );
};
